<template>
  <v-card
    class="product-slider-card catalog-card bottom bottom-center"
    elevation="0"
    flat
    :style="cardStyle"
    v-intersect.once="handleView"
    :id="`catalog-card-${proposal.id}`"
    :class="proposal.metaData.category_proposal_type.TEXT_POSITION"
  >
    <v-img
      eager
      :src="
        proposal.metaData.category_proposal_type.CARD_IMAGE.location
          ? imgSrc
          : imgLoaded
      "
      :alt="proposal.imgAlt"
      :title="proposal.imgDescription"
      :id="`proposal-image-${proposal.id}`"
    >
    </v-img>
    <v-card-title v-html="proposal.descr"></v-card-title>
    <v-card-actions>
      <v-btn class="pa-5" :href="customLink" outlined :style="btnStyle">{{
        proposal.metaData.category_proposal_type.TEXT_BTN
      }}</v-btn>
    </v-card-actions>
  </v-card>
</template>
<style lang="scss">
.catalog-card {
  display: flex;
  flex-direction: column;
  border-radius: 12px !important;
}
</style>
<script>
import banner from "~/mixins/banner";

import get from "lodash/get";

export default {
  name: "CatalogCard",
  props: {
    proposal: { type: Object, required: true },
    warehousePromoType: { type: Number, required: false }
  },
  data() {
    return {
      imgLoaded: null
    };
  },
  mixins: [banner],
  computed: {
    customLink() {
      if (
        this.proposal.proposalUrl &&
        this.proposal.slug &&
        this.proposal.metaData.category_proposal_type.WAREHOUSE_PROMO_TYPE
      ) {
        return (
          this.proposal.proposalUrl +
          "?targetCategory=" +
          this.proposal.slug +
          "&parent_warehouse_promo_facet_id=" +
          this.proposal.metaData.category_proposal_type.WAREHOUSE_PROMO_TYPE
        );
      } else if (this.proposal.proposalUrl) {
        return this.proposal.proposalUrl;
      } else if (this.proposal.slug) {
        if (this.proposal.slug == "listini-settimanali") {
          return (
            "/" +
            this.proposal.slug +
            `?q=&parent_warehouse_promo_facet_id=${this.warehousePromoType}`
          );
        } else {
          var categoryPrefix =
            global.config.categoryPrefix != undefined
              ? global.config.categoryPrefix
              : "/category/";
          return (
            this.proposal.proposalUrl || categoryPrefix + this.proposal.slug
          );
        }
      } else {
        return null;
      }
    },
    cardStyle() {
      let style = {};
      let backgroundCardColor = this.proposal.metaData.category_proposal_type
        .CARD_BG_COLOR;
      if (backgroundCardColor) {
        style.backgroundColor = backgroundCardColor;
      }
      let textColor = this.proposal.metaData.category_proposal_type.TEXT_COLOR;
      if (textColor) {
        style.color = textColor;
      }

      style.border = "thin solid rgba(0, 0, 0, 0.12) !important";

      return style;
    },
    btnStyle() {
      let style = {};
      let backgroundColor = this.proposal.metaData.category_proposal_type
        .BACKGROUND_BTN;
      if (backgroundColor) {
        style.backgroundColor =
          "var(--v-" + backgroundColor + "-base)" + "!important";
      }
      let colorBtn = this.proposal.metaData.category_proposal_type.COLOR_BTN;

      if (colorBtn) {
        style.color = colorBtn + "!important";
      }
      style.border = "none";
      style.width = "100%";
      return style;
    },
    imgSrc() {
      let prop =
        "metaData.category_proposal_type." +
        (!this.$vuetify.breakpoint.xsOnly ? "IMAGE_DESKTOP" : "IMAGE_MOBILE");
      return get(
        this.proposal,
        prop,
        this.proposal.metaData.category_proposal_type.CARD_IMAGE.location
      );
    }
  }
};
</script>
