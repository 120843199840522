import axios from "~/plugins/axios";
export default {
  getRecipeByProductId(productId, type = "light") {
    let url = `/p/wp-json/wp/v2/recipepid/${productId}/${type}`;
    return axios
      .get(url)
      .then(response => {
        return response.data;
      })
      .catch(err => err);
  },
  getPostsByProductId(productId, type = "light") {
    let url = `/p/wp-json/wp/v2/postsbypid/${productId}/${type}`;
    return axios
      .get(url)
      .then(response => {
        return response.data;
      })
      .catch(err => err);
  }
};
