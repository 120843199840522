<template>
  <v-card
    outlined
    class="product-card"
    :class="[promoClass]"
    :id="`product-card-${product.slug}`"
    :to="{ name: 'Product', params: { slug: product.slug } }"
    ref="productCard"
    :key="componentKey"
    @mouseover.once="getRecipeList"
  >
    <div class="product" @click="$emit('selectProduct')">
      <!-- <div class="promo-wrapper">
        <ProductClass
          :pClass="pClass"
          v-for="pClass in promoProductClasses"
          :key="pClass.productClassid"
        />
        <product-gift-certificate
          v-for="userGiftCertificate in product.userGiftCertificates"
          :key="userGiftCertificate.giftCertificate.giftCertificateId"
          :giftCertificate="userGiftCertificate.giftCertificate"
          :containerRef="$refs.productCard"
          @add="giftAdded"
        />
      </div> -->
      <div class="d-flex justify-space-between align-center w-100 top">
        <div class="product-badge" v-if="product.productInfos.BADGE">
          {{ product.productInfos.BADGE }}
        </div>
        <ProductPromo
          v-if="product.warehousePromo"
          :warehousePromo="product.warehousePromo"
        />
        <span
          v-else-if="product.newProduct == true"
          class="promo-box new-product"
        >
          NOVITA'
        </span>
        <!-- <v-spacer /> -->
        <!-- <ProductAcqLimit :product="product" /> -->
        <!-- <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <img
                v-bind="attrs"
                v-on="on"
                v-if="product.vendor.url"
                :src="product.vendor.url"
                height="26px"
                contain
              />
            </template>
            <span>{{ product.vendor.name }}</span>
          </v-tooltip> -->
        <div>
          <span class="cod-int text-caption">
            Cod.Art
            <span class="font-weight-bold">{{ product.codInt }}</span></span
          >

          <v-tooltip left :key="heartKey">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                @click.stop.prevent="addToFavorites"
                @mousedown.stop
                aria-label="Aggiungi ad una lista"
              >
                <transition name="pulse">
                  <v-icon color="primary" :key="highlight">
                    {{ highlight ? "$heartfull" : "$heart" }}
                  </v-icon>
                </transition>
              </v-btn>
            </template>
            <span>{{ $t("product.addToList") }}</span>
          </v-tooltip>
          <v-tooltip top v-if="recipeList && recipeList.length > 0">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                link
                :to="recipeList[0].recipe_url"
                aria-label="Vai alle ricette con questo prodotto"
              >
                <v-icon color="grey darken-1">$recipe</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("product.goToRecipe") }}</span>
          </v-tooltip>
        </div>
      </div>
      <div class="w-100">
        <div class="d-flex flex-column body">
          <img
            :src="product.mediaURL"
            onerror="this.onerror=null;this.src='/no-icon.png'"
            class="product-img align-self-center"
            :alt="'Immagine' + product.name"
            :title="
              product.name + ' (' + product.codInt + '-' + product.codVar + ')'
            "
          />

          <div
            class="description default--text d-flex flex-column justify-left w-100 mt-3 ml-1 ml-sm-0"
          >
            <h2 class="name font-weight-bold">{{ product.name }}</h2>
            <span class="short_descr">
              {{ product.description }} {{ product.shortDescr }}
              <span
                v-if="
                  product.productInfos &&
                    product.productInfos.TIPOLOGIA == 'Pezzo' &&
                    product.priceUmDisplay &&
                    product.weightUnitDisplay
                "
                >al {{ product.weightUnitDisplay }}
                {{ $n(product.priceUmDisplay, "currency") }}</span
              >
            </span>
            <!-- <span
              class="font-weight-bold text-uppercase"
              v-if="product.logisticPackagingItems"
              >{{ $t("product.packaging") }}
              {{ product.logisticPackagingItems }}
            </span> -->
            <!-- <span class="descr">
              <span class="font-weight-bold"></span>
              <span
                class="weight-unit"
                v-if="
                  product.productInfos &&
                    product.productInfos.TIPOLOGIA == 'Pezzo' &&
                    product.priceUmDisplay
                "
              >
                al {{ product.weightUnitDisplay }}
                {{ $n(product.priceUmDisplay, "currency") }}
              </span>
            </span> -->
            <span
              class="option d-flex align-center justify-space-between line-height-1 mt-2"
              style="white-space: initial;text-align: left;"
              v-for="(value, label) in selectedOptions"
              :key="label"
            >
              <div>
                <i>
                  Preferenza: <b> {{ value }}</b></i
                >
              </div>
              <!-- <SelectedOptionsMenu
                :product="product"
                :selectOptionsArray="selectOptionsArray"
                :size="'small'"
                @onOptionChanged="onOptionChanged"
              /> -->
            </span>

            <!-- <v-spacer /> -->
            <!-- <span
              v-if="
                product.warehousePromo && product.warehousePromo.view.bubble
              "
              class="promo-badge"
              v-html="product.warehousePromo.view.bubble"
            >
            </span> -->
            <!-- <div class="d-flex">
              <ProductClass
                :pClass="pClass"
                v-for="pClass in productClasses"
                :key="pClass.productClassid"
              />
              <span v-if="product.newProduct == true" class="new-badge">
                Novità
              </span>
            </div> -->
          </div>
        </div>

        <!-- <div
          v-if="selectedOptions.customWeight"
          class="cart-item-info"
          @click.stop.prevent="openOptionsModal(product.selectOptions)"
        >
          <em>Grammatura: {{ selectedOptions.customWeight }}gr</em>
        </div> -->
        <!-- v-if="product.priceDisplay && product.available > 0" -->
        <div class="d-flex justify-end price-wraper">
          <ProductPrice v-if="product.priceDisplay" :product="product" />
          <div class="iva align-self-end">
            {{ product.ivaCategory.descr }}
          </div>
        </div>
        <div v-if="product.available > 0" class="actions" @click.stop.prevent>
          <SelectedOptionsMenu
            v-if="quantity <= 0"
            :product="product"
            :selectOptionsArray="selectOptionsArray"
            :size="'medium'"
            @onOptionChanged="onOptionChanged"
            @setSelectOptions="setSelectOptions"
          />

          <v-tooltip top v-if="!showQntyInput" max-width="250">
            <template v-slot:activator="{ on, attrs }">
              <div class="w-100" v-bind="attrs" v-on="on">
                <ProductQty
                  :key="key"
                  :selectedOptions="selectedOptions"
                  :product="product"
                  :item="item"
                  :position="position"
                />
              </div>
            </template>
            <span
              class="db-click-tooltip d-block text-center"
              v-if="
                product.productInfos.TIPOLOGIA == 'Pezzo' ||
                  product.productInfos.TIPOLOGIA == 'Confezione'
              "
            >
              {{ $t("product.dbClickTooltip") }}
              <template v-if="product.logisticPackagingItems">
                <br />{{
                  $t("product.logisticPackagingTooltip", {
                    logisticPackagingItems: product.logisticPackagingItems
                  })
                }}
              </template>
              <template v-if="product.logisticPalletItems">
                <br />{{
                  $t("product.logisticPalletTooltip", {
                    logisticPalletItems: product.logisticPalletItems
                  })
                }}
              </template>
            </span>
            <span v-else class="no-db-click-tooltip d-block text-center">
              {{ $t("product.noDbClickTooltip") }}
            </span>
          </v-tooltip>
          <div class="product-qty" v-else>
            <div
              class="qnty-manual-input qty-wrap d-flex align-center rounded-md"
              :class="{ 'not-empty': quantity > 0 }"
              transition="fab-transition"
            >
              <a
                role="button"
                class="close px-2"
                @click.stop.prevent="toggleShowInput(false)"
                @mousedown.stop
              >
                <v-icon>$delete</v-icon>
              </a>
              <v-tooltip
                bottom
                :open-on-hover="false"
                :open-on-click="true"
                max-width="500"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="qntyInput"
                    ref="manualInput"
                    class="manual-input"
                    hide-details
                    autofocus
                    outlined
                    dense
                    v-mask="'###'"
                    type="number"
                    @click.stop.prevent=""
                    @keyup.enter="toggleShowInput(true)"
                    @keyup.esc="toggleShowInput(false)"
                    @mousedown.stop
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <span
                  class="qntity-tooltip"
                  v-if="
                    this.product.productInfos.QTY_LOCKED != 'true' ||
                      (this.product.productInfos.QTY_LOCKED == 'true' &&
                        !modulus(qntyInput))
                  "
                  v-html="
                    $t('product.quantity.confirmQntity', {
                      qntyInput: qntyInput
                    })
                  "
                >
                </span>
                <span
                  class="qntity-tooltip"
                  v-if="
                    this.product.productInfos.QTY_LOCKED == 'true' &&
                      modulus(qntyInput) &&
                      parseInt(qntyInput) <=
                        parseInt(this.product.productInfos.QTY_MULT)
                  "
                  v-html="
                    $t('product.quantity.editQntityMin', {
                      lowerInput: lowerInput
                    })
                  "
                >
                </span>

                <span
                  class="qntity-tooltip"
                  v-if="
                    this.product.productInfos.QTY_LOCKED == 'true' &&
                      modulus(qntyInput) &&
                      parseInt(qntyInput) >
                        parseInt(this.product.productInfos.QTY_MULT)
                  "
                  v-html="
                    $t('product.quantity.editQntityMinMax', {
                      lowerInput: lowerInput,
                      higherInput: higherInput
                    })
                  "
                >
                </span>
              </v-tooltip>
              <a
                role="button"
                class="check px-2"
                @click.stop.prevent="toggleShowInput(true)"
                @mousedown.stop
              >
                <v-icon>$success</v-icon>
              </a>
            </div>

            <v-btn
              depressed
              class="add-to-cart-btn"
              height="50"
              min-width="50"
              color="primary"
              @click.stop.prevent="
                toggleShowInput(true);
                addToCart(quantity, {
                  infos: { ...selectedOptions }
                });
              "
            >
              <v-icon>$cart</v-icon>
            </v-btn>
          </div>
        </div>

        <div
          v-else
          class="actions justify-center align-center text-center text-caption error--text font-weight-bold notAvailable"
        >
          {{ $t("product.notAvailable") }}
        </div>
        <div v-if="product.productInfos.OFFLINE_PURCHASE == 'true'">
          {{ $t("product.preventivo.text") }}
          <router-link
            :to="{
              path: '/richiedere-il-preventivo',
              query: { productId: product.productId }
            }"
          >
            {{ $t("product.preventivo.requestInfo") }}</router-link
          >
        </div>
      </div>
    </div>
  </v-card>
</template>
<style lang="scss">
.product-card {
  // .v-rating .v-icon {
  //   font-size: 16px;
  // }
  h2 {
    margin: 0px;
  }
  .old-price {
    padding: 4px 0px;
  }
  .cur-price {
    padding: 0px;
  }
  border: 1px solid #d5d5d5;
  border-radius: 12px 12px 12px 0px !important;
  .product-img {
    width: 170px;
    height: 170px;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      margin: 0px 17px;
      width: 100px;
      height: 100px;
    }
  }
  .pulse-enter,
  .pulse-leave {
    animation: pulse 2s infinite;
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
      box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
    }
    70% {
      -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
      box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    }
    100% {
      -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
      box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
  }
  .actions {
    min-height: auto !important;
    .v-menu__content {
      border-radius: $border-radius-root !important;
    }
    .selectOptions {
      margin-right: 10px;
    }
  }

  .notAvailable {
    height: 66px;
    font-size: 16px !important;
  }

  .selectOptionsMenu {
    z-index: 12;
  }
  .iva {
    font-size: 12px;
    white-space: nowrap;
  }
  .price-wraper {
    min-height: 60px;
  }
}
.product-card {
  transition: border-color 0.3s ease;
  &:hover {
    border: 1px solid $primary;
  }
}
</style>
<script>
// import ProductAcqLimit from "./ProductAcqLimit.vue";
import ProductPrice from "./ProductPrice.vue";
import ProductQty from "./ProductQty.vue";
// import ProductManualInput from "./ProductManualInput.vue";
// import ProductClass from "./ProductClass.vue";
// import ProductInfo from "./ProductInfo.vue";
// import LeadTime from "./ProductLeadTime.vue";
// import LockCutoff from "./ProductLockCutoff.vue";
// import AxBCutoff from "./ProductAxBCutoff.vue";
// import DayLock from "./ProductDayLock.vue";
// import ProductGiftCertificate from "@/components/gift/ProductGiftCertificate.vue";
import ProductPromo from "@/components/product/ProductPromo.vue";

import SelectedOptionsMenu from "@/components/product/SelectedOptionsMenu.vue";

import productMixin from "~/mixins/product";

import { mapState, mapGetters } from "vuex";

import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import LongPress from "vue-directive-long-press";
import { mask } from "vue-the-mask";
import WpSidalCustomService from "@/service/wpSidalCustomService";

export default {
  name: "ProductCard",
  props: {
    product: { type: Object, required: true },
    position: { type: Number, default: undefined }
  },
  directives: {
    "long-press": LongPress,
    mask: mask
  },
  components: {
    // ProductInfo,
    // ProductClass,
    // ProductAcqLimit,
    ProductPromo,
    ProductPrice,
    // ProductGiftCertificate,
    ProductQty,
    // ProductManualInput,
    SelectedOptionsMenu
    // LeadTime,
    // LockCutoff,
    // AxBCutoff,
    // DayLock
  },
  mixins: [productMixin],
  data() {
    return {
      selectOptionsArray: [],
      selectedOptions: {},
      componentKey: 0,
      key: 0,
      recipeList: []
    };
  },
  computed: {
    ...mapGetters({
      getItem: "cart/getItem",
      isAuthenticated: "cart/isAuthenticated"
    }),
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    promoProductClasses() {
      return this.product.productClasses.filter(
        pClass => pClass.productClassGroupId === 6
      );
    },
    // item() {
    //   this.key;
    //   let item = this.getItem({
    //     product: this.product,
    //     ...this.selectedOptions
    //   });
    //   return item;
    // },
    item() {
      return null;
    },
    promoClass() {
      let productClasses = [];
      let cssClass = get(this.product, "warehousePromo.view.cssClass");
      if (cssClass) {
        productClasses.push("has-promo");
        productClasses.push(cssClass);
      }
      if (this.product.isNew) {
        productClasses.push("new-product");
      }
      if (this.product.edge) {
        productClasses.push(this.product.badge.cssClass);
      }
      return productClasses;
    }
  },
  methods: {
    giftAdded() {
      this.plus();
    },
    onOptionChanged(args) {
      let selectOption = args.selectOption;
      let option = args.option;
      this.key++;
      this.selectedOptions[selectOption.parameterName] = option.key;
    },
    async setSelectOptions() {
      await this.needLogin("selectoptionsmenu");
    },
    handleClick() {
      this.$emit("selectProduct");
    },
    async getRecipeList() {
      this.recipeList = await WpSidalCustomService.getRecipeByProductId(
        this.product.productId
      );
    }
  },
  mounted() {
    if (this.product.selectOptions.length > 0) {
      this.selectOptionsArray = cloneDeep(this.product.selectOptions);
    }
  }
};
</script>
