<template>
  <div>
    <BannerSlider
      :config="config"
      :container="container"
      :isCard="isCard()"
      v-if="isCard()"
      :customProposals="customProposals"
    ></BannerSlider>
    <ProductSlider
      v-else
      :config="config"
      :container="container"
      :categoryBlockName="'CatalogSlider'"
    ></ProductSlider>
  </div>
</template>
<style lang="scss">
.catalog-slider {
  .product-slider {
    .product-qty {
      .icon {
        width: inherit !important;
        height: inherit !important;
      }
    }
  }
}
</style>
<script>
import BannerSlider from "./BannerSlider.vue";
import ProductSlider from "./ProductSlider.vue";

import deliveryReactive from "~/mixins/deliveryReactive";
import categoryBlockType from "./categoryBlockType";

// import CategoryService from "~/service/categoryService";

import Vue from "vue";
import ProductService from "~/service/productService";

export default {
  name: "CatalogSlider",
  props: {
    config: { type: Object, required: true },
    container: { type: Boolean, default: true }
  },
  mixins: [deliveryReactive, categoryBlockType],
  components: {
    BannerSlider,
    ProductSlider
  },
  data() {
    return {
      categories: [],
      customProposals: [],
      warehousePromoProducts: [],
      warehousePromoCategories: []
    };
  },
  methods: {
    isCard() {
      return Vue.$ebsn.meta(
        this.$props.config,
        "categoryblocktype_CatalogSlider.IS_CARD",
        false
      );
    },
    async fetchPromoProducts() {
      let res = await ProductService.search({
        q: null,
        page: 1,
        page_size: process.env.VUE_APP_PRODUCT_LIST_PAGE_SIZE,
        sort: null,
        promo: this.promoFilter,
        parent_warehouse_promo_facet_id: Vue.$ebsn.meta(
          this.config,
          "categoryblocktype_CatalogSlider.WAREHOUSE_PROMO_TYPE"
        ),
        new_product: false
      });
      return res.products;
    }
  },
  async created() {
    if (this.isCard()) {
      this.categories = this.$store.state.category.categoryTree;
      this.warehousePromoProducts = await this.fetchPromoProducts();
      for (let p = 0; p < this.warehousePromoProducts.length; p++) {
        let product = this.warehousePromoProducts[p];
        for (let t = 0; t < this.categories.length; t++) {
          let category = this.categories[t];
          let categoriesPath = this.$store.getters[
            "category/lookupCategoryPath"
          ](product.categoryId);
          if (categoriesPath[0].categoryId == category.categoryId)
            this.warehousePromoCategories.push(category);
        }
      }

      this.categories = this.warehousePromoCategories;

      if (this.categories.length > 0) {
        let customCard = {
          categoryProposalType: {},
          metaData: { category_proposal_type: { CARD_IMAGE: {} } }
        };
        customCard.categoryProposalType.name = "CatalogCard";
        customCard.slug = Vue.$ebsn.meta(
          this.config,
          "categoryblocktype_CatalogSlider.CUSTOM_CATEGORY"
        );
        customCard.priority = 1;
        customCard.metaData.category_proposal_type.CARD_IMAGE.location = Vue.$ebsn.meta(
          this.config,
          "categoryblocktype_CatalogSlider.CARD_IMAGE.location"
        );
        customCard.metaData.category_proposal_type.TEXT_BTN = Vue.$ebsn.meta(
          this.config,
          "categoryblocktype_CatalogSlider.TEXT_BTN"
        );
        customCard.metaData.category_proposal_type.TEXT_COLOR = Vue.$ebsn.meta(
          this.config,
          "categoryblocktype_CatalogSlider.TEXT_COLOR"
        );
        customCard.metaData.category_proposal_type.TEXT_POSITION = Vue.$ebsn.meta(
          this.config,
          "categoryblocktype_CatalogSlider.TEXT_POSITION"
        );
        customCard.metaData.category_proposal_type.BACKGROUND_BTN = Vue.$ebsn.meta(
          this.config,
          "categoryblocktype_CatalogSlider.MAIN_BACKGROUND_BTN"
        );
        customCard.metaData.category_proposal_type.CARD_BG_COLOR = Vue.$ebsn.meta(
          this.config,
          "categoryblocktype_CatalogSlider.MAIN_CARD_BG_COLOR"
        );
        customCard.metaData.category_proposal_type.BACKGROUND_COLOR = Vue.$ebsn.meta(
          this.config,
          "categoryblocktype_CatalogSlider.BACKGROUND_COLOR"
        );
        customCard.metaData.category_proposal_type.COLOR_BTN = Vue.$ebsn.meta(
          this.config,
          "categoryblocktype_CatalogSlider.MAIN_COLOR_BTN"
        );

        this.customProposals.push(customCard);

        for (let c = 0; c < this.categories.length; c++) {
          let customProposal = {
            categoryProposalType: {},
            metaData: { category_proposal_type: { CARD_IMAGE: {} } }
          };

          customProposal.categoryProposalType.name = "CatalogCard";
          customProposal.slug = this.categories[c].slug;
          customProposal.priority = c + 2;
          customProposal.descr = this.categories[c].name;
          customProposal.img = this.categories[c].img;
          customProposal.imgAlt = this.categories[c].name;
          customProposal.imgDescription = this.categories[c].name;
          customProposal.link = this.categories[c].itemUrl;
          customProposal.proposalUrl = "/listini-settimanali";
          customProposal.metaData.category_proposal_type.WAREHOUSE_PROMO_TYPE = Vue.$ebsn.meta(
            this.config,
            "categoryblocktype_CatalogSlider.WAREHOUSE_PROMO_TYPE"
          );
          customProposal.metaData.category_proposal_type.CARD_IMAGE.location = Vue.$ebsn.meta(
            this.categories[c],
            "category_info.CARD_IMAGE.location"
          );
          customProposal.metaData.category_proposal_type.TEXT_BTN = Vue.$ebsn.meta(
            this.config,
            "categoryblocktype_CatalogSlider.TEXT_BTN"
          );
          customProposal.metaData.category_proposal_type.TEXT_COLOR = Vue.$ebsn.meta(
            this.config,
            "categoryblocktype_CatalogSlider.TEXT_COLOR"
          );
          customProposal.metaData.category_proposal_type.TEXT_POSITION = Vue.$ebsn.meta(
            this.config,
            "categoryblocktype_CatalogSlider.TEXT_POSITION"
          );
          customProposal.metaData.category_proposal_type.BACKGROUND_BTN = Vue.$ebsn.meta(
            this.config,
            "categoryblocktype_CatalogSlider.BACKGROUND_BTN",
            "red"
          );
          customProposal.metaData.category_proposal_type.CARD_BG_COLOR = Vue.$ebsn.meta(
            this.config,
            "categoryblocktype_CatalogSlider.CARD_BG_COLOR",
            "white"
          );
          customProposal.metaData.category_proposal_type.BACKGROUND_COLOR = Vue.$ebsn.meta(
            this.config,
            "categoryblocktype_CatalogSlider.BACKGROUND_COLOR"
          );
          customProposal.metaData.category_proposal_type.COLOR_BTN = Vue.$ebsn.meta(
            this.config,
            "categoryblocktype_CatalogSlider.COLOR_BTN"
          );

          if (
            customProposal.metaData.category_proposal_type.WAREHOUSE_PROMO_TYPE
          ) {
            this.customProposals.push(customProposal);
          }
        }

        const tmpArr = this.customProposals.reduce((acc, current) => {
          if (!acc.some(item => item.slug === current.slug)) {
            acc.push(current);
          }
          return acc;
        }, []);
        this.customProposals = tmpArr;
      }
    }
  }
};
</script>
