<template>
  <ProductListSlider
    :config="config"
    :products="products"
    :categoryBlockName="
      categoryBlockName && categoryBlockName.length > 0
        ? categoryBlockName
        : 'ProductSlider'
    "
    :showMore="showMore"
    v-intersect.once="handleView"
    @selectProduct="handleClick"
  />
</template>

<script>
import ProductListSlider from "./elements/ProductListSlider.vue";

// import categoryBlockType from "./categoryBlockType";
import deliveryReactive from "~/mixins/deliveryReactive";

import ProductService from "~/service/productService";
import analyticsService from "~/service/analyticsService";
import get from "lodash/get";

import Vue from "vue";

export default {
  name: "ProductSlider",
  props: {
    config: {
      type: Object,
      required: true
    },
    container: {
      type: Boolean,
      default: true
    },
    categoryBlockName: {
      type: String,
      required: false,
      default: "ProductSlider"
    }
  },
  components: { ProductListSlider },
  mixins: [deliveryReactive],
  data() {
    return {
      products: [],
      showMore: false
    };
  },
  methods: {
    // handleView(entries, observer, isIntersecting) {
    //   if (isIntersecting) {
    //     analyticsService.viewPromotions(
    //       [this.proposal],
    //       this.position,
    //       this.products
    //     );
    //   }
    // },
    async reload() {
      this.products = [];

      let categoryId = Vue.$ebsn.meta(
        this.config,
        `categoryblocktype_${this.categoryBlockName}.CATEGORY.categoryId`
      );
      if (categoryId) {
        let response = await ProductService.search({
          parent_category_id: categoryId,
          page_size: Vue.$ebsn.meta(
            this.config,
            `categoryblocktype_${this.categoryBlockName}.PRODUCT_LIMIT`,
            12
          )
        });
        this.products = response.products;
        this.showMore = response.page.totItems > response.page.itemsPerPage;
        if (!this.products || this.products.length < 1) {
          this.$emit("hide");
        }
        // this.$emit("loaded", this.products ? this.products.length : 0);
      }
    },
    handleView(entries, observer, isIntersecting) {
      if (isIntersecting) {
        const position = get(this.config, "templateBlock.codInt");
        analyticsService.viewProducts(this.products, this.getName(), position);
      }
    },
    handleClick(payload) {
      analyticsService.clickProduct(
        payload.product,
        this.getName(),
        payload.index
      );
    },
    getName() {
      const categoryId = get(
        this.config,
        `metaData.categoryblocktype_${this.categoryBlockName}.CATEGORY.categoryId`
      );
      const proposalTitle = get(
        this.config,
        `metaData.categoryblocktype_${this.categoryBlockName}.TITLE`
      );
      return proposalTitle || "promo_" + categoryId;
    }
  },
  async created() {
    await this.reload();
  }
};
</script>
